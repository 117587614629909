// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";
// import Moment from 'moment';

import CrazyNutz from './assets/img/crazynut.jpg';

import MainLogo from './assets/img/logo.png';
import CustomBtn from './assets/img/custom-btn.png';
import { isAddress } from 'ethers/lib/utils';

// Pages

// hooks
import { useSignup } from './hooks/useSignup';


function App() {

  const queryParameters = new URLSearchParams(window.location.search);
  var referrerWallet = queryParameters.get("referral");

  const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("Copied");
	  setTimeout(() => setCopied(false), 3000);
	}

  const [counter, setCounter] = useState(0);

  const { walletSignup, registering, setRegistering, registerFeedback, setRegisterFeedback, registerSuccess, setRegisterSuccess } = useSignup();

  // Set state for blockchain data
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

	const userRegister = async () => {
    if (referrerWallet == null || referrerWallet == "") {
      referrerWallet = "0x687159e4f2786b10c977569bbb3a0c9c72873eaf";
    }
    console.log("Final Wallet:", referrerWallet);
    if (referrerWallet.toLowerCase() == blockchain.account.toLowerCase()) {
      setRegisterFeedback("不能推荐自己的钱包地址！");
      return;
    }
    if (!isAddress(referrerWallet) || referrerWallet.length < 42) {
      setRegisterFeedback("钱包地址不符合标准，请检查链接！");
      return;
    }

    await walletSignup(blockchain.account, referrerWallet);

    setTimeout(() => fetchUserData(), 2000);

	  // blockchain.rideChain.methods.register(referrerWallet).send({
    //   gasLimit: 80000,
    //   maxPriorityFeePerGas: 3000000000,
    //   maxFeePerGas: 3000000000,
    //   from: blockchain.account,
    //   value: 0,
	  // })
	  // // Transaction fail
	  // .once("error", (err) => {

	  // })
	  // // Transaction success
	  // .then((receipt) => {

	  // });
	};

  const [userData, setUserData] = useState(null);
  const fetchUserData = async () => {
    const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
    const json = await response.json();
    if (response.ok) {
        if (json.length === 1) {
          // Expect only 1 result
          setUserData(json[0]);
        } else {
          console.log("Invalid user data: More than 1 result.");
        }
    }
	};

	// const fetchUserData = () => {
	//   blockchain.rideChain.methods.getUserInfos(blockchain.account).call()
	//   .then((results) => {
  //     console.log("Connected User Wallet Data", results);
  //     setUserData(results);
	//   });
	// };

  const [investData, setInvestData] = useState([]);
	const fetchInvestData = () => {
	  blockchain.rideChain.methods.getOrderInfos(blockchain.account).call()
	  .then((results) => {
		console.log("Connected User Investment Data", results);
		setInvestData(results);
	  });
	};

  const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
	  blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0x55d398326f99059fF775485246999027B3197955").call()
	  .then((results) => {
		console.log("USDT Balance:", results);
		setUSDTBalance(results);
	  });
	};

  const [allowance, setAllowance] = useState(0);
	const checkTokenAllowance = () => {
	  blockchain.usdtContract.methods.allowance(blockchain.account, "0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4").call()
	  .then((results) => {
		console.log("Token Allowance:", results);
		setAllowance(results);
	  });
	};

  const [approving, setApproving] = useState(false);
	const approveToken = () => {
	  setApproving(true);
	  blockchain.usdtContract.methods
	  .approve("0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4", blockchain.web3.utils.toWei((100000).toString(), "ether"))
	  .send({
		gasLimit: 80000,
		maxPriorityFeePerGas: 3000000000,
		maxFeePerGas: 3000000000,
		from: blockchain.account,
		value: 0,
	  })
	  // Transaction fail
	  .once("error", (err) => {
		setApproving(false);
	  })
	  // Transaction success
	  .then((receipt) => {
		setTimeout(() => checkTokenAllowance(), 3000);
		setTimeout(() => setApproving(false), 5000);
	  });
	};

  const [investAmount, setInvestAmount] = useState(300);

  const [investing, setInvesting] = useState(false);
	const [investSuccess, setInvestSuccess] = useState(false);
	const investUSDT = (_amount, _referrer) => {
    if (!isAddress(_referrer)) {
      console.log("Invalid referrer address.");
      fetchUserData();
      return;
    }
    console.log("Submit deposit with referrer:", _referrer);
	  setInvesting(true);
	  blockchain.rideChain.methods
	  .invest(_amount, false, "0x687159e4f2786b10c977569bbb3a0c9c72873eaf", _referrer)
	  .send({
		gasLimit: 180000,
		maxPriorityFeePerGas: 3000000000,
		maxFeePerGas: 3000000000,
		from: blockchain.account,
		value: 500000000000000,
	  })
	  // Transaction fail
	  .once("error", (err) => {
		setInvesting(false);
	  })
	  // Transaction success
	  .then((receipt) => {
		setInvestSuccess(true);
		setTimeout(() => fetchInvestData(), 3000);
		setTimeout(() => checkTokenAllowance(), 3000);
		setTimeout(() => setInvesting(false), 5000);
	  });
	};

  const getData = () => {
    if (blockchain.account !== "" && blockchain.rideChain !== null) {
      fetchUserData();
      getUserUSDTBalance();
      checkTokenAllowance();
      fetchInvestData();
    }
  };

  useEffect(() => {
    getData();
    console.log("Referrer Wallet from URL:", referrerWallet);
  }, [blockchain.account]);


  return (
    <div className="d-flex justify-content-center">
      <div className="mobile-view">

        <section className="header">
          <div className="container">
            <div className="row">
              <div className="col-8 my-2 px-2">
                <h3 className="brand-primary-color-light mb-0">CrazyNutz</h3>
              </div>
              <div className="col-4 my-2 px-2 text-right">
              </div>
            </div>
          </div>
        </section>



        <Router>
            
          <Routes>
            <Route path="/" element={
              <>
                <section className="">
                  <div className="container">
                    <div className="row">

                      <div className="col-12 mt-3 px-2 text-center">
                        <div className="data-box">
                          <h5 className="brand-primary-color-dark mb-0">Score:</h5>
                          <h4 className="brand-primary-color-dark mb-0">{counter}</h4>
                        </div>
                      </div>

                      <div className="col-12 p-0 text-center">
                        <button className="btn p-0" onClick={(e) => {
                          setCounter((prev) => prev + 1);
                        }}>
                          <img className="w-100" src={CrazyNutz}></img>
                        </button>
                      </div>

                      <div className="col-12 mt-3 text-center">
                        <h5 className="">NutListz</h5>
                        <MiddleEllipsis><span className=""></span></MiddleEllipsis>
                        <CopyToClipboard 
                          text={"https://crazynutz.xyz?referral="}
                          onCopy={() => copyReferral()}>
                          <button className="btn custom-btn" onClick={(e) => {
                          }}>
                            <p className="mb-0">Connect</p>
                          </button>
                        </CopyToClipboard>
                      </div>

                      <div className="col-12 mt-3 text-center">
                        <h5 className="">NutFrenz</h5>
                        <MiddleEllipsis><span className=""></span></MiddleEllipsis>
                        <CopyToClipboard 
                          text={"https://crazynutz.xyz?referral="}
                          onCopy={() => copyReferral()}>
                          <button className="btn custom-btn" onClick={(e) => {
                          }}>
                            {!copied ? (
                              <p className="mb-0">Copy</p>
                            ):(
                              <p className="mb-0">{copyText}</p>
                            )}
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>

                  </div>
                </section>
              </>
            }/>
          </Routes>

        </Router>
      </div>
    </div>
  );
}

export default App;